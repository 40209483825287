*{
  margin: 0;
  padding: 0;
}
p{
  margin: 0 !important;
}
/* Footer styling */
.footer-bar{
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  bottom: 0; 
  left: 0;
  margin-top: 10px; 
  width: 100%; 
  box-sizing: border-box;
}

.element-mawingu {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  max-width: 360px;
  left: 30px;
}
/* main menu styling */
.landing-page {
  /* background-image: url('../assets/landing-page.jpg'); */
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  /* overflow: scroll; */
  /* min-height: 100vh; */
}
.overlap-group {
  max-width: 680px;
  margin-left: 10%;
  margin-bottom: 20px;
}
.text-wrapper-7 {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
  padding: 0 !important;
}
.modern-facilities {
  color: #1E99D7;
  font-size: 46px;
  font-weight: 500;
  padding: 0 !important;
}
.topsearch-bar {
  border-radius:8px;
  top:10px;
  /* margin-left: 35%; */
}
.mainmenu-container
{
  height: 40vh;
  overflow: hidden;
  padding-top: 35px;
}

.search-input {
  border-radius: 20px;
  background-color: white; 
  font-size: 16px;
  outline: none;
}

.bottom-buttons1 {
  width: 100%; 
  max-width: 680px;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}
.topsearchcards {
  display:flex;
  gap: 20px;
  justify-content: space-around;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.framecard {
  margin-top: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 30px;
  border-radius: 15px;
  text-align: left;
  flex: 0 0 45%;
}

.div-wrapper {
  align-items: center;
  background-color: #1E99D7;
  color: #fff;
  border-radius: 50px;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  flex: 0 0 12.5%;
}

.div-wrapper1 {
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  flex: 0 0 12.5%;
}
.text-wrapper-2 {
  color: #000429;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: 27px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.text-wrapper-24 {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: 27px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
/* Dashboard page */
.top-nav {
  display: flex;
  justify-content: space-between;
}

.auth-button {
  border: none;
  color:#0a1128;
  border-radius: 5px;
  cursor: pointer;
  background: none;
  font-size: large;
  font-weight: bolder;
}
.registerbtn{
  margin-top: 29px;
  padding: 10px;
  width:250px
}
.button-container {
display: inline-flex;
gap: 50px;
align-items: left;
margin-left: 0;



/* Adds space between the buttons */
}


.button-2 {
  background-color: #1E99D7;
  border-radius: 15px;
  padding: 5px 20px;
  position: fixed;
  left: -40px;
  top: 50%; 
  transform: translateY(-50%) rotate(-90.27deg); 
}
.text-wrapper-4 {
color: #ffffff;
font-family: "DM Sans", Helvetica;
font-size: 10px;
font-weight: 900;
letter-spacing: 0;
line-height: normal;
margin-left: -3px;
margin-right: -3px;
margin-top: -1px;
position: relative;
width: 101px;
}
.chat {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 115px;
  right: 20px; 
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1E99D7;
  cursor: pointer;
  z-index: 1000;
}


.chat img {
  width: 24px;
  height: 24px;
}


/* General Page Styles */
.research-page {
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
padding: 20px;
text-align: left;
}

/* Left Section */
.left-section .title {
color: #3498db;
font-weight: bold;
font-size: 24px;
text-transform: capitalize;
width: 100%;
}

.content-box {
border-radius: 8px;
padding: 5px 15px 5px 0px;
}

.icon-label {
display: flex;
align-items: center;
margin-top: 10px;
}

.icon-label img {
width: 20px;
height: 20px;
margin-right: 8px;
}

/* Search Box */
.search-box {
align-items: center;
background-color: #ffffff;
border-radius: 30px;
padding: 2px;
margin-top: 20px;
width: 50%;
min-width: 100px;
}

.search-icon {
color: #888;
margin-right: 8px;
}

/* Right Section */
.right-section {
background-color: #0a1128;
color: #ffffff;
padding: 30px;
border-radius: 20px;
height: 90vh;
}

.related-btn {
background-color: #ffffff;
color: #000;
font-weight: bold;
width: 100%;
padding: 10px;
border-radius: 20px;
border: none;
margin-bottom: 20px;
}

.question-list {
list-style: none;
padding: 0;
margin: 0;
}

.question-list li {
margin-bottom: 10px;
}

.see-list {
color: #00bcd4;
margin-top: 20px;
}

.topics {
display: flex;
flex-wrap: wrap;
gap: 10px;
margin-top: 10px;
}

.topic-btn {
background-color: transparent;
color: #ffffff;
border: 1px solid #ffffff;
padding: 5px 10px;
border-radius: 20px;
font-size: 12px;
}

.chat-btn-container {
position: absolute;
bottom: 20px;
right: 20px;
}

.chat-btn {
background-color: #3498db;
border: none;
color: #ffffff;
width: 50px;
height: 50px;
border-radius: 50%;
font-size: 24px;
display: flex;
align-items: center;
justify-content: center;
}

.chat-with-community{
display: flex;
}
.cards {
display: flex;
flex-wrap: wrap;
gap: 20px;
margin-left: 50%;
max-width: 590px;
background-color: transparent;
}
.header1 {
display: flex;
align-items: center;
margin-bottom: 20px;
fontsize: 40px;
}

/* .cards {
display: flex;
flex-wrap: wrap;
gap: 20px;
justify-content: center;
}

.card {
width: 150px;
height: 150px;
border-radius: 15px;
overflow: hidden;
position: relative;
cursor: pointer;
transition: all 0.3s ease-in-out;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card:hover {
transform: translateY(-5px);
}

.card-image {
width: 100%;
height: 100%;
object-fit: cover;  /* Ensures image covers the card without distortion */
/* }

.card-content {
position: absolute;
bottom: 0;
left: 0;
right: 0;
background: rgba(0, 0, 0, 0.6); /* Dark overlay for readability */
/* color: white;
padding: 10px;
text-align: center; */
/* } */
/* 
.card-title {
/* font-size: 18px;
font-weight: bold; */
/* }  */

/* .read-more {
font-size: 14px;
color: #00aaff;
text-decoration: none;
font-weight: bold;
} */

/* .read-more:hover {
color: #007acc;
} */


/* .cards {
display: flex;
flex-wrap: wrap;
gap: 20px;
justify-content: center;
} */

.card {
width: 180px;
height: 180px;
border-radius: 15px;
overflow: hidden;
position: relative;
cursor: pointer;
transition: all 0.3s ease-in-out;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
background-color: transparent;

}
.card2 {

border-radius: 15px;
overflow: hidden;
position: relative;
cursor: pointer;
transition: all 0.3s ease-in-out;
/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
background-color: transparent;

}

.card:hover {
transform: translateY(-5px);
}

.card-image {
width: 100%;
height: 100px; /* Adjust the image height */
object-fit: cover; /* Ensures the image scales correctly */
border-radius: 20px;
}

.card-content {
padding: 1px;
align-items: center;
}

.card-title {
font-size: 5px;
font-weight: bold;
margin-bottom: 10px;
color: #333;
}

.card-description {
font-size: 10px;
color: #1E99D7;
margin-bottom: 15px;
font-weight: bold;
padding: 10px;

}


.read-more {
font-size: 10px;
font-weight: bold;
color:black;
text-decoration: none;
float: right;
border: 1px solid white;
background-color: white;
border-radius: 15px;
width: fit-content;
padding: 2px;
}

.read-more:hover {
color: #0056b3;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
.cards {
  grid-template-columns: repeat(2, 1fr); /* 2 cards per row on tablets */
}
}

@media (max-width: 480px) {
.cards {
  grid-template-columns: repeat(1, 1fr); /* 1 card per row on small screens */
}
}
